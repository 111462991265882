var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('reDelivery.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('reDelivery.pageTitle'),
                href: '/admin/agency/re-deliveries'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('h3',{staticClass:"tw-uppercase"},[_c('small',[_vm._v(_vm._s(_vm.$t("reDelivery.packageList")))])]),_c('div',{staticClass:"tw-border tw-rounded-md tw-p-2",staticStyle:{"min-height":"500px"}},[_c('div',{staticClass:"tw-relative tw-mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"tw-w-full tw-border tw-py-2 tw-px-3 tw-rounded-md tw-outline-none",attrs:{"type":"text","placeholder":_vm.$t('reDelivery.searchBox')},domProps:{"value":(_vm.search)},on:{"change":_vm.onSearch,"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('button',{staticClass:"tw-absolute tw-right-0 tw-top-0 tw-mr-3 tw-mt-2",on:{"click":_vm.fetchDelivery}},[_c('i',{staticClass:"fas fa-search"})])]),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.deliveryList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.transaction_date))]),_c('td',[_vm._v(_vm._s(record.delivery_number))]),_c('td',[_vm._v(_vm._s(record.shop_code))]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.driver_name))]),_c('td',{staticClass:"tw-text-center"},[_c('button',{on:{"click":function($event){return _vm.addItem(record)}}},[_c('i',{staticClass:"fas fa-plus"})])])]}}])})],1)]),_c('div',{staticClass:"col-md-3 tw-flex tw-flex-col"},[_c('h3',{staticClass:"tw-uppercase"},[_c('small',[_vm._v(_vm._s(_vm.$t("reDelivery.packageAdded")))])]),_c('div',{staticClass:"tw-border tw-rounded-md tw-p-2 tw-flex-1 tw-h-full tw-overflow-y-scroll"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.remark),expression:"model.remark"}],staticClass:"tw-w-full tw-border tw-py-2 tw-px-3 tw-rounded-md tw-outline-none tw-top-0 tw-sticky",class:{
                                    'is-invalid': _vm.errors.has('remark')
                                },attrs:{"type":"text","placeholder":_vm.$t('reDelivery.remark')},domProps:{"value":(_vm.model.remark)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "remark", $event.target.value)}}}),(_vm.errors.has('remark'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("remark"))+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col tw-space-y-2 tw-mt-2"},_vm._l((_vm.model.delivery_id_list),function(delivery,index){return _c('div',{key:index,staticClass:"tw-p-2 tw-border tw-rounded-md tw-flex tw-justify-between"},[_c('div',[_c('h4',[_c('small',[_vm._v(_vm._s(delivery.transaction_date))])]),_c('b',[_vm._v(_vm._s(delivery.delivery_number))]),_c('p',{staticClass:"tw-m-0"},[_vm._v(" "+_vm._s(delivery.driver_name)+" ")])]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.$t("reDelivery.shopName")))]),_c('p',{staticClass:"tw-m-0 tw-text-md"},[_vm._v(" "+_vm._s(delivery.shop_name)+" ")]),_c('button',{staticClass:"tw-text-red-500 tw-float-right tw-mt-3",on:{"click":function($event){$event.preventDefault();return _vm.removItem(index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])}),0)])])]),_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-mt-3"},[_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 're-delivery' })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("saveAddNew")))]),_c('ts-button',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("save")))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }