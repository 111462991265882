<template>
    <div>
        <ts-page-title
            :title="$t('reDelivery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('reDelivery.pageTitle'),
                    href: '/admin/agency/re-deliveries'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="row">
                        <div class="col-md-9">
                            <h3 class="tw-uppercase">
                                <small>{{
                                    $t("reDelivery.packageList")
                                }}</small>
                            </h3>
                            <div
                                class="tw-border tw-rounded-md tw-p-2"
                                style="min-height: 500px;"
                            >
                                <div class="tw-relative tw-mb-2">
                                    <input
                                        v-model="search"
                                        type="text"
                                        class="tw-w-full tw-border tw-py-2 tw-px-3 tw-rounded-md tw-outline-none"
                                        :placeholder="
                                            $t('reDelivery.searchBox')
                                        "
                                        @change="onSearch"
                                    />
                                    <button
                                        @click="fetchDelivery"
                                        class="tw-absolute tw-right-0 tw-top-0 tw-mr-3 tw-mt-2"
                                    >
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                                <ts-table
                                    :columns="columns"
                                    :records="deliveryList"
                                >
                                    <template v-slot="{ record }">
                                        <td>{{ record.transaction_date }}</td>
                                        <td>{{ record.delivery_number }}</td>
                                        <td>{{ record.shop_code }}</td>
                                        <td>{{ record.shop_name }}</td>
                                        <td>{{ record.driver_name }}</td>
                                        <td class="tw-text-center">
                                            <button @click="addItem(record)">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </td>
                                    </template>
                                </ts-table>
                            </div>
                        </div>
                        <div class="col-md-3 tw-flex tw-flex-col">
                            <h3 class="tw-uppercase">
                                <small>{{
                                    $t("reDelivery.packageAdded")
                                }}</small>
                            </h3>
                            <div
                                class="tw-border tw-rounded-md tw-p-2 tw-flex-1 tw-h-full tw-overflow-y-scroll"
                            >
                                <input
                                    v-model="model.remark"
                                    type="text"
                                    class="tw-w-full tw-border tw-py-2 tw-px-3 tw-rounded-md tw-outline-none tw-top-0 tw-sticky"
                                    :placeholder="$t('reDelivery.remark')"
                                    :class="{
                                        'is-invalid': errors.has('remark')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('remark')"
                                >
                                    {{ errors.first("remark") }}
                                </div>
                                <div
                                    class="tw-flex tw-flex-col tw-space-y-2 tw-mt-2"
                                >
                                    <div
                                        class="tw-p-2 tw-border tw-rounded-md tw-flex tw-justify-between"
                                        v-for="(delivery,
                                        index) in model.delivery_id_list"
                                        :key="index"
                                    >
                                        <div>
                                            <h4>
                                                <small>{{
                                                    delivery.transaction_date
                                                }}</small>
                                            </h4>
                                            <b>{{
                                                delivery.delivery_number
                                            }}</b>

                                            <p class="tw-m-0">
                                                {{ delivery.driver_name }}
                                            </p>
                                        </div>
                                        <div>
                                            <small>{{
                                                $t("reDelivery.shopName")
                                            }}</small>
                                            <p class="tw-m-0 tw-text-md">
                                                {{ delivery.shop_name }}
                                            </p>
                                            <button
                                                class="tw-text-red-500 tw-float-right tw-mt-3"
                                                @click.prevent="
                                                    removItem(index)
                                                "
                                            >
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-end tw-space-x-2 tw-mt-3">
                        <ts-button
                            @click.prevent="
                                $router.push({ name: 're-delivery' })
                            "
                            :disabled="loading"
                            >{{ $t("cancel") }}</ts-button
                        >
                        <ts-button
                            color="primary"
                            outline
                            @click.prevent="onSaveAddNew"
                            >{{ $t("saveAddNew") }}</ts-button
                        >
                        <ts-button color="primary" @click.prevent="onSave">{{
                            $t("save")
                        }}</ts-button>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { debounce, includes } from "lodash";
import { mapActions } from "vuex";
export default {
    name: "re-delivery-create",
    data() {
        return {
            loading: false,
            search: "",
            resources: [],
            errors: new Errors(),
            model: {
                remark: "",
                delivery_id_list: []
            }
        };
    },
    computed: {
        columns() {
            return [
                { name: this.$t("reDelivery.transactionDate") },
                { name: this.$t("reDelivery.transactionNumber") },
                { name: this.$t("reDelivery.shopCode") },
                { name: this.$t("reDelivery.shopName") },
                { name: this.$t("reDelivery.driverName") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 40px"
                }
            ];
        },
        deliveryList() {
            return this.resources.filter(
                r =>
                    !includes(
                        this.model.delivery_id_list.map(el => el.delivery_id),
                        r.delivery_id
                    )
            );
        }
    },
    methods: {
        ...mapActions("agency/reDelivery", ["store", "getFormViewData"]),
        fetchDelivery() {
            this.loading = true;
            this.getFormViewData({
                search: this.search
            })
                .then(response => {
                    this.resources = response.data.delivery;
                })
                .finally(() => (this.loading = false));
        },
        addItem(row) {
            this.model.delivery_id_list.push(row);
        },
        onSearch: debounce(function() {
            this.fetchDelivery();
        }, 500),
        removItem(index) {
            this.model.delivery_id_list.splice(index, 1);
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "re-delivery" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.remark = "";
            this.model.delivery_id_list = [];
            this.fetchDelivery();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchDelivery();
        });
    }
};
</script>
